<template>
  <div class="users">
    <Breadcrumb name1="义链宇宙" name2="愿望批次" />
    <!-- 卡片视图区域 -->
    <el-card class="box-card">
      <el-row :gutter="20">
        <el-col :span="4">
          <!-- 添加用户区域 -->
          <el-button type="primary" @click="addDialogVisible = true">添加批次</el-button>
        </el-col>
      </el-row>
      <!-- 用户列表 -->
      <el-table :data="dataList" stripe style="width: 100%" border>
        <el-table-column prop="id" label="ID"></el-table-column>
        <el-table-column label="作品名称">
          <template v-slot="scope">
            {{ scope.row.art_info.name }}
          </template>
        </el-table-column>
        <el-table-column prop="start_token_id" label="开始tokenId"></el-table-column>
        <el-table-column prop="end_token_id" label="截止tokenId"></el-table-column>
        <el-table-column prop="start_date" label="开始日期" width="160">
          <template v-slot="scope">
            {{ (scope.row.start_date * 1000) | dataFormat }}
          </template>
        </el-table-column>
        <el-table-column prop="end_date" label="结束日期" width="160">
          <template v-slot="scope">
            {{ (scope.row.end_date * 1000) | dataFormat }}
          </template>
        </el-table-column>
        <el-table-column prop="is_open" label="是否开启" :formatter="formatIsOpen"></el-table-column>
        <el-table-column prop="valid_quantity" label="最低总票数"></el-table-column>
        <el-table-column prop="created_on" label="创建时间" width="160">
          <template v-slot="scope">
            {{ (scope.row.created_on * 1000) | dataFormat }}
          </template>
        </el-table-column>
        <el-table-column label="操作" width="130px">
          <template v-slot="scope">
            <!-- 修改按钮 -->
            <el-button
              type="primary"
              icon="el-icon-edit"
              size="mini"
              @click="showEditDialog(scope.row)"
            ></el-button>

            <!-- 删除按钮 -->
            <el-button
              type="danger"
              icon="el-icon-delete"
              size="mini"
              @click="removeById(scope.row.id)"
            ></el-button>
          </template>
        </el-table-column>
      </el-table>

      <!-- 分页 -->
      <el-pagination
        @current-change="handleCurrentChange"
        :current-page="queryInfo.page"
        @size-change="handleSizeChange"
        :page-size="queryInfo.page_size"
        :page-sizes="[10, 20, 30, 40]"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </el-card>
    <!-- 添加用户对话框 -->
    <el-dialog
      title="愿望批次"
      :visible.sync="addDialogVisible"
      :close-on-click-modal=false
      width="50%"
      @close="addDislogClosed"
    >
      <!-- 内容主题区域 -->
      <el-form
        label-width="150px"
        ref="addForm"
        :model="addForm"
        :rules="addFormRules"
      >
        <el-form-item label="是否开启" prop="is_open">
          <el-switch v-model="addForm.is_open" active-value="1" inactive-value="2"></el-switch>
        </el-form-item>
        <el-form-item label="选择作品" prop="art_info_id">
          <el-select v-model="addForm.art_info_id" placeholder="请选择">
            <el-option
              v-for="item in wishOptions"
              :key="item.id"
              :label="item.name"
              :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="开始tokenId" prop="start_token_id">
          <el-input v-model="addForm.start_token_id" type="number"></el-input>
        </el-form-item>
        <el-form-item label="截止tokenId" prop="end_token_id">
          <el-input v-model="addForm.end_token_id" type="number"></el-input>
        </el-form-item>
        <el-form-item label="开始日期" prop="start_date">
          <el-date-picker
            v-model="addForm.start_date"
            type="datetime"
            placeholder="选择日期时间"
            value-format="timestamp">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="结束日期" prop="end_date">
          <el-date-picker
            v-model="addForm.end_date"
            type="datetime"
            placeholder="选择日期时间"
            value-format="timestamp">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="最低总票数" prop="valid_quantity">
          <el-input v-model="addForm.valid_quantity" type="number"></el-input>
        </el-form-item>
      </el-form>
      <!-- 底部按钮区域 -->
      <span slot="footer" class="dialog-footer">
        <el-button @click="addDislogClosed()">取 消</el-button>
        <el-button type="primary" @click="submitForm">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { wishBatchFormRulesMixin } from '@/common/mixin.js'
import Breadcrumb from 'components/content/breadcrumb/Breadcrumb'
export default {
  name: 'WishBatch',
  mixins: [wishBatchFormRulesMixin],
  data() {
    return {
      // 获取用户列表的参数对象
      queryInfo: {
        // 搜索值
        // query: '',
        // 当前的页数
        page: 1,
        // 当前每次显示多少条数据
        page_size: 10,
      },
      // 存放用户的数据和数量
      dataList: [],
      total: 0,
      // 控制用户对话框的显示和隐藏
      addDialogVisible: false,
      // 添加用户数据的对象
      addForm: {
        id: '',
        is_open: '1',
        art_info_id: '',
        start_token_id: '',
        end_token_id: '',
        start_date: '',
        end_date: '',
        valid_quantity: '',
      },
      wishOptions:[],
    }
  },
  components: {
    Breadcrumb,
  },
  created() {
    this.getDataList()
    this.getInfoList()
  },
  methods: {
    //获取义链
    async getInfoList() {
      this.$http({
        method: 'get',
        url: '/api/v1/admin/art-info/wish',
        params: this.queryInfo,
        timeout: 5000,
        headers: {
          token: window.sessionStorage.getItem('token'),
          // 'X-Requested-With': 'XMLHttpRequest',
          'Content-Type': 'application/json; charset=UTF-8',
        },
        // loading: loading
      })
        .then((res) => {
          this.wishOptions = res.data.result;
        })
        .catch((res) => {
          this.$message.error('获取愿望批次列表失败!')
        })
    },
    //获取愿望批次列表
    async getDataList() {
      this.$http({
        method: 'get',
        url: '/api/v1/admin/wish-batch/list',
        params: this.queryInfo,
        timeout: 5000,
        headers: {
          token: window.sessionStorage.getItem('token'),
          // 'X-Requested-With': 'XMLHttpRequest',
          'Content-Type': 'application/json; charset=UTF-8',
        },
        // loading: loading
      })
        .then((res) => {
          this.$message.success('获取愿望批次列表成功!')
          this.dataList = res.data.result.list
          this.total = res.data.result.pager.total_rows
        })
        .catch((res) => {
          this.$message.error('获取愿望批次列表失败!')
        })
    },
    // 监听 page_size 改变事件 每页显示的个数
    handleSizeChange(newSize) {
      // console.log(newSize)
      this.queryInfo.page_size = newSize
      this.getDataList()
    },
    // 监听 页码值 改变的事件 当前页面值
    handleCurrentChange(newPage) {
      console.log(newPage)
      this.queryInfo.page = newPage
      this.getDataList()
    },
    // 监听添加用户的对话框关闭事件
    addDislogClosed() {
      this.addDialogVisible = false;
      this.addForm = {
        id: '',
        is_open: false,
        art_info_id: '',
        start_token_id: '',
        end_token_id: '',
        start_date: '',
        end_date: '',
        valid_quantity: '',
      }
    },
    submitForm() {
      const _that = this
      this.$refs['addForm'].validate((valid) => {
        if (valid) {
          _that.addForm.start_date = parseInt(_that.addForm.start_date/1000);
          _that.addForm.end_date = parseInt(_that.addForm.end_date/1000);
          _that.addForm.art_info_id = Number(_that.addForm.art_info_id)
          _that.addForm.start_token_id = Number(_that.addForm.start_token_id)
          _that.addForm.end_token_id = Number(_that.addForm.end_token_id)
          _that.addForm.valid_quantity = Number(_that.addForm.valid_quantity)
          _that.addForm.is_open = Number(_that.addForm.is_open)
          if (_that.addForm.id) {
            _that
              .$http({
                method: 'put',
                url: '/api/v1/admin/wish-batch',
                data: _that.addForm,
                timeout: 5000,
                headers: {
                  token: window.sessionStorage.getItem('token'),
                  'Content-Type': 'application/json; charset=UTF-8',
                },
              })
              .then((res) => {
                if (res.data.message == "success"){
                  _that.$message.success('修改成功');
                }else{
                  _that.$message.error(res.data.message)
                }
                _that.addDislogClosed();
                _that.getDataList();
              })
              .catch((res) => {
                return _that.$message.error('修改操作失败了哦,请检查数据后重试')
              })
          } else {
            _that
              .$http({
                method: 'post',
                url: '/api/v1/admin/wish-batch',
                data: _that.addForm,
                timeout: 5000,
                headers: {
                  token: window.sessionStorage.getItem('token'),
                  'Content-Type': 'application/json; charset=UTF-8',
                },
              })
              .then((res) => {
                if (res.data.message == "success"){
                  _that.$message.success('新增成功');
                }else{
                  _that.$message.error(res.data.message)
                }
                _that.addDislogClosed();
                _that.getDataList();
              })
              .catch((res) => {
                return _that.$message.error('新增操作失败了哦,请检查数据后重试')
              })
          }
          
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    // 展示编辑用于的对话框
    showEditDialog(row) {
      this.addForm = {
        id: row.id,
        art_info_id: row.art_info_id,
        start_token_id: row.start_token_id,
        end_token_id: row.end_token_id,
        start_date: row.start_date*1000,
        end_date: row.end_date*1000,
        valid_quantity: row.valid_quantity,
        is_open: row.is_open.toString(),
      }
      
      this.addDialogVisible = true
    },

    // 根据id删除对应的用户信息
    async removeById(id) {
      // 询问用户是否删除用户
      const confirmRusult = await this.$confirm(
        '此操作将删除该愿望批次?',
        '删除该愿望批次',
        {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        }
      ).catch((err) => err)
      // console.log(confirmRusult)
      // 用户点击了删除,则返回字符串 confirm
      // 用户取消了删除,则返回字符串 cancel
      if (confirmRusult !== 'confirm') {
        return this.$message.info('已经取消了删除')
      }
      let _that = this
      _that
        .$http({
          method: 'delete',
          url: '/api/v1/admin/wish-batch/' + id,
          timeout: 10000,
          headers: {
            token: window.sessionStorage.getItem('token'),
            'Content-Type': 'application/json; charset=UTF-8',
          },
        })
        .then((res) => {
          if (res.data.message == "success"){
            _that.$message.success('删除成功');
          }else{
            _that.$message.error(res.data.message)
          }
          this.getDataList()
        })
        .catch((res) => {
          return this.$message.error('该愿望批次删除失败')
        })
    },
    formatIsOpen:function(row, index){
      if (row.is_open == 1) {
        return '是' //根据自己的需求设定
      }else{
        return '否'
      }
    },
  },
}
</script>

<style lang="less" scoped>
.el-table {
  margin-top: 15px;
}
</style>
