import { render, staticRenderFns } from "./WishBatch.vue?vue&type=template&id=13220629&scoped=true&"
import script from "./WishBatch.vue?vue&type=script&lang=js&"
export * from "./WishBatch.vue?vue&type=script&lang=js&"
import style0 from "./WishBatch.vue?vue&type=style&index=0&id=13220629&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "13220629",
  null
  
)

export default component.exports